const PROMETHEUS_BASE = 'https://prometheus.zapier.com';
const PAGE_SIZE = 100;
let $GLOBAL_SERVICE_DIV;
let SERVICE_LIST = [];
let SEARCH_QUERY = '';
let INITIAL_RENDER = false;
let RENDER_QUEUE = [];


const makeClass = service => {
    let postfix = service.key.replace('@', '__').replace(/\./g, '_');
    return `service-${postfix}`;
};

const alertHTML = (name, selectedApi, action, service) => {
    return `
        <li>
            <span class="service-logo PlaceholderAPI64x64 ${selectedApi}64x64"></span> 
            <a href="https://zapier.com/app/editor/?create=true&template__0__action=newApi&template__0__selected_api=AppStatusCLIAPI%401.0.0&template__0__type_of=read&template__0__params__apps=${service.key}&template__0__meta__parammap__apps__0=${service.title || service.slug || service.key}&template__1__action=${action}&template__1__selected_api=${selectedApi}&template__1__type_of=write">
                ${name} Alert
            </a>
        </li>
    `;
};

const hasOpenIncident = service => {
    return service.incidents.some(incident => {
        return incident.status !== 'resolved' && incident.status !== 'scheduled';
    });
};

const createAppItem = service => {
    // Yup, a template thing or some ReactJS would be much better here!
    let apiClassName = `${service.key.split('@')[0]}64x64`;
    let statusColour = 'green';
    let iconClass = 'icon-check';
    let tooltip = '';

    if (hasOpenIncident(service)) {
        statusColour = 'red';
        iconClass = 'icon-error';
        tooltip = 'No information available.';

        let message = service.incidents[0].message;
        if (message) {
            tooltip = message;
        }
    }

    let tooltipClassName = tooltip ? 'tooltipster' : '';

    return $(`
        <div class="app-status-item ${makeClass(service)}">
            <div class="zap-updates icon-more"></div>
            <div class="zap-updates-popover">
                <ul>
                    ${alertHTML('Slack', 'SlackAPI', 'channel_message', service)}
                    ${alertHTML('SMS', 'SMSAPI', 'send_v2', service)}
                    ${alertHTML('Email', 'ZapierMailAPI', 'outbound', service)}
                </ul>
            </div>
            <div class="service-ident">
                <div class="service-logo PlaceholderAPI64x64 ${apiClassName} ${tooltipClassName}" title="${tooltip}">
                    <div class="service-status-icon service-status--${statusColour} ${iconClass}">
                    </div>
                </div>
                <a title="${service.title}" href="https://zapier.com/zapbook/${service.slug}" class="service-name">
                    ${service.title}
                </a>
            </div>
            <div class="app-status-response">
                <h5>Response Time</h5>
                <span class="response-time">&nbsp;</span>
            </div>
            <div class="app-status-response-graph">
                <h5>Past Hour</h5>
                <div class="response-time-graph"></div>
            </div>
        </div>
    `);
};

const isInteresting = service => {
    if (!service.title) {
        return false;
    }
    let numDataPoints = service.data.length;
    let nonZeroDataPoints = service.data.filter(point => point > 0);
    if (nonZeroDataPoints.length > numDataPoints / 2) {
        return true;
    }
    return false;
};

const addAppItem = service => {
    let $appItem = createAppItem(service);

    if (hasOpenIncident(service)) {
        $GLOBAL_SERVICE_DIV.prepend($appItem);
    } else if (isInteresting(service)) {
        $GLOBAL_SERVICE_DIV.append($appItem);
    }
    // need this so any services added to the list after a search
    // has started know to hide/show themselves
    if (SEARCH_QUERY) {
        showOrHideService(service, SEARCH_QUERY);
    }

    return $appItem;
};

const buildGraph = service => {
    if (isInteresting(service)) {
        let graphData = service.data.map((responseTime, key) => [key, responseTime]);
        let responseTime = Math.round(graphData[graphData.length - 1][1]);
        let serviceClass = makeClass(service);

        $(`.${serviceClass} .response-time`).text(`${responseTime}ms`);
        $.plot(
            $(`.${serviceClass} .response-time-graph`),
            [
                {
                    data: graphData,
                    color: '#354147',
                    shadowSize: 0
                }
            ],
            {
                xaxis: {
                    show: false
                },
                yaxis: {
                    show: true,
                    position: "left",
                    min: 0,
                    font: {
                        size: 10,
                        lineHeight: 13,
                        color: "#999"
                    },
                    tickFormatter: (val, axis) => val.toLocaleString() + "ms",
                    ticks: 1
                },
                grid: {
                    color: '#fff',
                    borderWidth: 1,
                    borderColor: {
                        bottom: '#999'
                    }
                },
                series: {
                    lines: {
                        show: true,
                        lineWidth: 1
                    },
                    points: {
                        show: false
                    }
                }
            }
        );
    }
};

const addPopupClickListener = service => {
    $(`.${makeClass(service)} .zap-updates`).click(function(e) {
        e.stopPropagation();
        let $popover = $(this).parent().children('.zap-updates-popover');
        let popoverIsOpen = $popover.is(':visible');
        $('.zap-updates-popover').hide();
        if (!popoverIsOpen) {
            $popover.show();
        }
    });
};

const showOrHideService = (service, query) => {
    if (searchMatch(service, query)) {
        $(`.${makeClass(service)}`).show();
    } else {
        $(`.${makeClass(service)}`).hide();
    }
};

const searchMatch = (service, query) => service.title.toLowerCase().indexOf(query) !== -1;

const searchApps = _.debounce(e => {
    let query = $(e.target).val().toLowerCase();
    SEARCH_QUERY = query;
    if (query) {
        SERVICE_LIST.forEach(service => {
            showOrHideService(service, query);
        });

        if (RENDER_QUEUE.length) {
            // Ugh. The service might be pending a render, find it, render, then show it
            for (let i = 0; i < RENDER_QUEUE.length; i++) {
                if (searchMatch(RENDER_QUEUE[i], query)) {
                    renderService(RENDER_QUEUE[i]);
                    showOrHideService(RENDER_QUEUE[i], query);
                    RENDER_QUEUE.splice(i, 1);
                }
            }
        }
    } else {
        // show them all!
        $('.app-status-item').show();
    }
}, 300);  // ms

const addAppStatus = () => {
    let $tabContainer = $('<div id="tab-container" />');

    // metrics stuff
    let $customMetrics = $('#custom-metrics-container');
    let $incidentsList = $('.incidents-list');
    $incidentsList.attr('id', 'incidents-list');

    // app status stuff
    let $header = $('<span>App Status</span>');
    $header.addClass('font-largest');
    let $appStatus = $('<div id="app-status" />');

    let $searchContainer = $('<div class="api-status-search" />');
    let $searchInputContainer = $('<div class="search-api-input" />');
    $searchInputContainer.append($('<span class="icon-search" />'));
    $searchInputContainer.append($('<input class="search-api-input" placeholder="Search Apps" />'));

    $searchContainer.append($searchInputContainer);
    $appStatus.append($searchContainer);

    let $servicesContainer = $('<div id="all-global-services" />');
    $appStatus.append($servicesContainer);

    let $spinner = $('<div id="spinner" />');
    $appStatus.append($spinner);

    let $tabMenu = $('<ul class="tab-menu" />');
    $tabMenu.append($('<li><a href="#custom-metrics-container">System Metrics</a></li>'));
    $tabMenu.append($('<li><a href="#app-status">App Status</a></li>'));
    $tabMenu.append($('<li><a href="#incidents-list">Incident History</a></li>'));

    $customMetrics.wrap($tabContainer);

    $('#custom-metrics-container .font-largest').remove();

    $tabContainer = $('#tab-container');
    $tabContainer.prepend($tabMenu);
    $tabContainer.append($appStatus);

    $incidentsList.detach().appendTo($tabContainer);
    $('#incidents-list .font-largest').remove();

    $('input.search-api-input').on('input', searchApps);

    $tabContainer.easytabs();

    $GLOBAL_SERVICE_DIV = $servicesContainer;

    $spinner.spin({position: 'relative'})
    $(window).scroll(_.throttle(lazyRender, 300));

    loadPages(
        loadNewServices,
        (err) => {$spinner.spin(false); console.log("unable to load data (site probably down?)", err);},
        () => {$spinner.spin(false)}
    );
};

const loadNewServices = data => {
    data.services.forEach(service => {
        SERVICE_LIST.push(service);
        if (!INITIAL_RENDER || hasOpenIncident(service)) {
            renderService(service);
        } else if (SEARCH_QUERY && searchMatch(service, SEARCH_QUERY)) {
            // ongoing search, immediately render
            renderService(service);
        } else {
            RENDER_QUEUE.push(service);
        }
    });

    if (!INITIAL_RENDER) {
        INITIAL_RENDER = true;
    }
};

const loadPages = (onPageComplete, onPageError, onAllComplete, page) => {
    page = page || 1;

    function nextPage(data) {
        if (data.page < data.pages) {
            loadPages(onPageComplete, onPageError, onAllComplete, page + 1);
        } else {
            onAllComplete();
        }
    }

    $.getJSON(`${PROMETHEUS_BASE}/api/v1/services/?data=yes&page=${page}`)
        .done([onPageComplete, nextPage])
        .fail(onPageError);
};

const renderService = (service) => {
    let $appItem = addAppItem(service);
    $appItem.ready(() => {
        $('html').click(() => {
            $('.zap-updates-popover').hide();
        });
        addPopupClickListener(service);
        buildGraph(service);
        $(`.${makeClass(service)} .tooltipster`).tooltipster();
    })
};

const lazyRender = () => {
    let scrollOffset = $(window).scrollTop() + $(window).height();
    let triggerThreshold = $(document).height() - 100;

    if (INITIAL_RENDER && scrollOffset > triggerThreshold) {
        for (let i = 0; i < PAGE_SIZE && RENDER_QUEUE.length; i++) {
            renderService(RENDER_QUEUE.shift());
        }
    }
};

$().ready(() => {
    addAppStatus();
});
